import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../api/apiUser';

const stripePromise = loadStripe('pk_live_51Lsbr5JCMXrkQlvrbJOLD79CQbEcxhEjAUPGE92dSQnYZPJSyfkcBRGeh0kC9H3q0EHYnjoRwxCh0syttlK8m6Oe00whSIMOLD');

// Internal button component using Stripe hooks
const CheckoutButtonContent = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleCheckout = async () => {
    if (!stripe || !elements) return;
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    });
    const session = await response.json();

    const result = await stripe.redirectToCheckout({ sessionId: session.id });
    if (result.error) {
      console.error(result.error.message);
    }
  };

  return (
    <Button variant="primary" onClick={handleCheckout} disabled={!stripe}>
      Pay Now
    </Button>
  );
};

// Main CheckoutButton with Elements context
const CheckoutButton = () => (
  <Elements stripe={stripePromise}>
    <CheckoutButtonContent />
  </Elements>
);

export default CheckoutButton;

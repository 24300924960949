import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getToken } from '../api/apiUser';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Pay.css';


const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);


const SignInOverlay = () => (
  <div className="overlay">
    <div className="overlay-content">
      <p>You must sign in to buy a course!</p>
      <a href="/accounts">Sign In</a>
    </div>
  </div>
);

const CheckoutForm = ({ priceId }) => {
  const navigate = useNavigate();
  const createPaymentIntent_ = async (paymentMethodId, price) => {
    try {

        const paymentIntentResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/create-checkout-session`,
            {
                paymentMethodId: paymentMethodId,
                priceId: priceId,
            },
            {
                headers: {
                Authorization: 'Bearer ' + getToken(),
                },
            }
            );
            
  
      if (paymentIntentResponse.error) {
        console.log('Payment Intent Creation Failed:', paymentIntentResponse.error);
      } else {
        console.log('Payment Intent Created:', paymentIntentResponse);
        localStorage.setItem('isPaid', true);
        //navigate to the course here.
        
        axios.get(
            `${process.env.REACT_APP_API_URL}/register_anki_instance`,
            {
                headers: {
                Authorization: 'Bearer ' + getToken(),
                },
            }
        );

        navigate('/make_anki');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error occurred: ' + error.message);
    }
  };
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      alert('Error occurred: ' + error.message);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      await createPaymentIntent_(paymentMethod.id);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>Pay</button>
    </form>
  );
};

const Pay = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [priceId, setPriceId] = useState('');
  const [courses, setCourses] = useState([
    { id: 'basic', name: 'Basic Subscription', price: 9.99 },
  ]);



  const handleCourseSelection = (event) => {
    console.log(event.target);
    setSelectedCourse(event.target.value);
    // Assume each course has a different price ID
    setPriceId(event.target.value);
  };

  return (
    <div>
      <div className="pay-container">
        <div className="pay-content">
          <h1 className="pay-title">Payment Gateway</h1>
          <p className="pay-description">Enter your card information to proceed with payment:</p>
          <select className="select-course" onChange={handleCourseSelection} value={selectedCourse}>
            <option value="">Select a Subscription</option>
            {courses.map(course => (
              <option key={course.id} value={course.id}>
                {`${course.name} - $${course.price}`}
              </option>
            ))}
          </select>

          {selectedCourse && (
            <Elements stripe={stripePromise}>
              <CheckoutForm priceId={priceId} />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pay;

import React, { useState } from 'react';
import './CardComponent.css';


const CardComponent = ({ card, uploadFunc, rejectFunc }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="card-container">
      <div className={`flip-card ${isFlipped ? 'flipped' : ''}`}>
        <div className="flip-card-inner">
          {/* Front Side (English Side) */}
          <div className="flip-card-front">
            <div className="card-content">
              <p>{card.english_word}</p>
              {/* Map over the array of English sentences */}
              {card.english_sentences.map((sentence, index) => (
                <p key={index}>{sentence}</p>
              ))}
              {card.img_url && (
                <div className="image-section">
                  <img src={card.img_url} alt="Card Image" className="card-image" />
                </div>
              )}
            </div>
          </div>

          {/* Back Side (Non-English Side) */}
          <div className="flip-card-back">
            <div className="card-content">
              <p>{card.non_english_word}</p>
              {/* Map over the array of Non-English sentences */}
              {card.non_english_sentences.map((sentence, index) => (
                <p key={index}>{sentence}</p>
              ))}
            </div>
          </div>
        </div>

        {/* Checkmark and X buttons */}
        <div className="card-actions">
          <button className="reject-button" onClick={rejectFunc}>✖</button>
          <button className="accept-button" onClick={uploadFunc}>✔</button>
        </div>
      </div>

      {/* Flip Button */}
      <button className="flip-button" onClick={handleFlip}>
        {isFlipped ? "Show English Side" : "Show Non-English Side"}
      </button>
    </div>
  );
};


export default CardComponent;

import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, CssBaseline, Avatar, Grid, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha";
import { logIn } from '../api/apiUser';

export default function Signup() {
  const navigate = useNavigate();
  const [recaptchaResponse, setRecaptchaResponse] = useState(null); 
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const validateField = (name, value) => {
    let tempErrors = { ...errors };

    switch (name) {
      case 'email':
        tempErrors.email = /\S+@\S+\.\S+/.test(value) ? '' : 'Please enter a valid email';
        break;
      case 'password':
        tempErrors.password = value.length >= 6 ? '' : 'Password must be at least 6 characters long';
        break;
      case 'confirmPassword':
        tempErrors.confirmPassword = value === formData.password ? '' : 'Passwords do not match';
        break;
      default:
        break;
    }

    setErrors(tempErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Update the form data
    setFormData({
      ...formData,
      [name]: value
    });

    // Validate the specific field as it changes
    validateField(name, value);
  };

  const  handleSubmit = async (e) => {
    e.preventDefault();
    if (!errors.email && !errors.password && !errors.confirmPassword) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/sign_up`, 
            {formData, recaptchaResponse}
            , {
            headers: {
                'Content-Type': 'application/json',
            }});
        console.log('Submitted ', formData, response);
        Swal.fire({
          icon: 'success',
          title: 'Account Registered',
          text: 'Your account was successfully created!',
        });
        navigate('/login')
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Account Failed to Register',
          text: 'An error occured. Are you already registered?',
        });
        console.log(error)
      }
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaResponse(value);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={formData.password}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword}
              />
            </Grid>
            <Grid item xs={12}>
            <ReCAPTCHA
            sitekey="6Lcl7EEpAAAAAMHEZDFGOLVQetZH-8H_zSWN2AzO"
            onChange={handleRecaptchaChange}
            />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={Boolean(errors.email || errors.password || errors.confirmPassword)}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Log in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

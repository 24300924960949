import {axios, backendUri} from "./config";
import Swal from 'sweetalert2';

export const logIn = async (props) => {
    try {
    const res = await axios.post(`${backendUri}/log_in`, props);
    localStorage.setItem('email', res.data.email);
    localStorage.setItem('authToken', res.data.token);
    localStorage.setItem('isPaid', res.data.isPaid);
    Swal.fire({
        icon: 'success',
        title: 'Log in Successful',
        text: 'You have logged in.',
      });
      return 200
    } catch (error) {
        console.log(error);
        Swal.fire({
            icon: 'error',
            title: 'Account Failed to Register',
            text: 'An error occured. Are you already registered?',
          });
          return 401
    }
}

export function getToken() {
    return localStorage.getItem("authToken");
}

  
export function isPaid(){
    console.log('IS PAID:', localStorage.getItem("isPaid"));
    return localStorage.getItem("isPaid");
}
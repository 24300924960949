import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { startNewDroplet, deleteDroplet } from '../api/apiUser';
import axios from 'axios';
import CardComponent from './CardComponent';
import Swal from 'sweetalert2';
import { getToken } from '../api/apiUser';

export default function PostCard(selectedDeck) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [word, setWord] = useState(null);
  const [translateFrom, setTranslateFrom] = useState('');
  const [translateTo, setTranslateTo] = useState('');
  const [card, setCard] = useState(null);
  const [generateImage, setGenerateImage] = useState(false); // New state for Generate Image

  const commonLanguages = [
    { code: 'English', label: 'English' },
    { code: 'Spanish', label: 'Spanish' },
    { code: 'French', label: 'French' },
    { code: 'German', label: 'German' },
    { code: 'Italian', label: 'Italian' },
    { code: 'Chinese', label: 'Chinese' },
    { code: 'Japanese', label: 'Japanese' },
    { code: 'Russian', label: 'Russian' },
  ];

  const postCard = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const deckName = selectedDeck.selectedDeck;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/post_card`, {
        word,
        translateFrom,
        translateTo,
        deckName,
        generateImage, 
      }, {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      console.log('response:', response);

      setCard({
        deck_name: response.data.data.deck_name,
        english_word: response.data.data.english_word,
        non_english_word: response.data.data.non_english_word,
        english_sentences: response.data.data.english_sentences,
        non_english_sentences: response.data.data.non_english_sentences,
        img_url: response.data.data.img_url,
      });

    } catch (error) {
      setError('Failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const uploadCard = async () => {
    console.log('Uploading Card');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload_card`, card, {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      Swal.fire({
        icon: 'success',
        title: 'Card Uploaded!',
        text: 'The card was successfully uploaded',
      });
      console.log(response);
      setCard(null);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Card Upload Error',
        text: 'The card failed to upload',
      });
      console.log(error);
    }
  };

  const rejectCard = async () => {
    console.log('reject');
    Swal.fire({
      icon: 'success',
      title: 'Card Rejected',
      text: 'You rejected the card. Generate another.',
    });
    setCard(null);
  };
  
  return (
    <Container className="mt-3">
      <Row className="mb-4 p-4 pb-3 bg-body-tertiary hover-effect" style={{ borderRadius: '10px' }}>
        {/* Input box for the word */}
        <Form.Group className="mb-3" controlId="formWordInput">
          <Form.Label>Word</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter word"
            value={word}
            onChange={(e) => setWord(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formTranslateFrom">
          <Form.Label>Translate From</Form.Label>
          <Form.Select
            value={translateFrom}
            onChange={(e) => setTranslateFrom(e.target.value)}
          >
            <option value="">Select Language</option>
            {commonLanguages.map((language) => (
              <option key={language.code} value={language.code}>
                {language.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formTranslateTo">
          <Form.Label>Translate To</Form.Label>
          <Form.Select
            value={translateTo}
            onChange={(e) => setTranslateTo(e.target.value)}
          >
            <option value="">Select Language</option>
            {commonLanguages.map((language) => (
              <option key={language.code} value={language.code}>
                {language.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        {/* Checkbox for Generate Image */}
        <Form.Group className="mb-3" controlId="formGenerateImage">
          <Form.Check
            type="checkbox"
            label="Generate Image"
            checked={generateImage}
            onChange={(e) => setGenerateImage(e.target.checked)}
          />
        </Form.Group>

        <Button onClick={postCard} disabled={loading || !word || !selectedDeck}> {/* Disable if word or deck is empty */}
          {loading ? 'Posting Card...' : 'Post Card'}
        </Button>
        {error && <p className="text-danger mt-3">Error: {error}</p>}

        {card && (
          <div>
            <CardComponent card={card} uploadFunc={uploadCard} rejectFunc={rejectCard} />
          </div>
        )}
      </Row>
    </Container>
  );
}
